<template>
  <div>
    <v-breadcrumbs class="pa-0 mb-2" :items="[{ text: $t('main.back') }]">
      <template #item="{ item }">
        <v-breadcrumbs-item class="global-cursor-pointer" @click="backToRoute()">
          <v-icon color="black" size="15">
            $arrow-left
          </v-icon>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="black rounded pa-4 pa-sm-5 mb-3 mb-sm-4">
      <div class="white--text text-body-1 text-sm-h6 font-weight-bold">
        {{ ad.name }}
      </div>
    </div>
    <v-row no-gutters class="mb-lg-3">
      <v-col cols="12" lg="4" class="order-lg-1">
        <new-ads-actions
          class="mb-3 mb-lg-0"
          :ad="ad"
          :is-loading="adIsLoading"
          @update="fetchAd()"
        />
      </v-col>
      <v-col cols="12" lg="8">
        <ad-info :ad="ad" :is-loading="adIsLoading" class="mb-3 pr-lg-2 mb-lg-0" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" lg="8" class="pr-lg-2">
        <v-alert v-if="adStatusIsCreating" text color="info" class="mb-1 pr-6">
          <div class="d-flex">
            <v-icon size="16" color="info" class="mr-4 pt-5">
              $update
            </v-icon>
            <div class="text-body-2 max-width">
              {{ $t('ads.creatives_are_creating') }}
            </div>
          </div>
        </v-alert>
        <component
          :is="creativeCard"
          v-if="ad.model.creatives && ad.model.creatives.length"
          class="mb-3 mb-lg-0"
          :ad="ad"
          :ad-is-loading="adIsLoading"
          :active-creative-is-loading="activeCreativeIsLoading"
          @active-creative-handler="setCreativeStatus($event)"
        />
      </v-col>
      <v-col cols="12" lg="4">
        <v-row class="my-2 my-lg-0" no-gutters>
          <v-col class="mb-2 pr-sm-1 pr-lg-0 mb-lg-3" cols="12" sm="6" lg="12">
            <parameters-card :ad-is-loading="adIsLoading" :ad="ad" />
          </v-col>
          <v-col class="pl-sm-1 pl-lg-0" cols="12" sm="6" lg="12">
            <ad-campaigns :campaigns="ad.campaigns" :is-loading="adIsLoading" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'
  import handleErrors from '@/services/handleErrors.js'
  import NewAdsActions from '@/views/Ad/NewAdsActions.vue'
  import AdInfo from '@/views/Ad/AdInfo.vue'
  import PopunderCreativesCard from '@/views/Ad/PopunderCreativesCard.vue'
  import BannerCreativesCard from '@/views/Ad/BannerCreativesCard.vue'
  import PushCreativesCard from '@/views/Ad/PushCreativesCard.vue'
  import InStreamCreativesCard from '@/views/Ad/InStreamCreativesCard.vue'
  import NativeCreativeCard from '@/views/Ad/NativeCreativeCard.vue'
  import InterstitialCreativesCard from '@/views/Ad/InterstitialCreativesCard.vue'
  import IosCalendarCreativesCard from '@/views/Ad/IosCalendarCreativesCard.vue'
  import InPageCreativesCard from '@/views/Ad/InPageCreativesCard.vue'
  import ParametersCard from '@/views/Ad/ParametersCard.vue'
  import AdCampaigns from '@/views/Ad/AdCampaigns.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'NewAdPage',
    components: {
      AdCampaigns, ParametersCard, AdInfo, NewAdsActions
    },
    data() {
      return {
        ad: new Ads(),
        adIsLoading: false,
        activeCreativeIsLoading: false
      }
    },
    computed: {
      ...mapState('settings', ['backRouteName']),
      adId() {
        return this.$route.params.id
      },
      creativeCard() {
        return {
          [adFormats.POPUNDER]: PopunderCreativesCard,
          [adFormats.BANNER]: BannerCreativesCard,
          [adFormats.WEB_PUSH]: PushCreativesCard,
          [adFormats.IN_STREAM_AD]: InStreamCreativesCard,
          [adFormats.NATIVE_AD]: NativeCreativeCard,
          [adFormats.INTERSTITIAL]: InterstitialCreativesCard,
          [adFormats.IOS_CALENDAR]: IosCalendarCreativesCard,
          [adFormats.IN_PAGE_AD]: InPageCreativesCard
        }[this.ad.adFormatType]
      },
      adStatusIsCreating() {
        return this.ad.status === 'creating'
      }
    },
    created() {
      this.fetchAd()
    },
    methods: {
      async setCreativeStatus(params) {
        try {
          this.activeCreativeIsLoading = true
          const creativeStatusRequest = Ads.transformCreativeStatusRequest({
            adId: this.adId,
            ...params
          })
          const response = await adsRepository.setCreativeStatus(creativeStatusRequest)
          this.ad = new Ads(response)
        } catch (error) {
          handleErrors(error)
          await this.fetchAd()
        }
        this.activeCreativeIsLoading = false
      },
      async backToRoute() {
        if (this.backRouteName) {
          this.$router.go(-1)
        }
        await this.$router.push({ name: routeNames.ADS })
      },
      async fetchAd() {
        try {
          this.adIsLoading = true
          const response = await adsRepository.show(this.adId)
          this.ad = new Ads(response)
        } catch (error) {
          handleErrors(error)
        }
        this.adIsLoading = false
      }
    }
  }
</script>

<style scoped lang="scss">
.max-width {
  max-width: 640px;
}
</style>
