<template>
  <div class="fill-height preview rounded d-flex justify-center black">
    <div v-show="statePreview === 'playing'" class="preview__main">
      <iframe
        :key="statePreview"
        ref="frame"
        class="preview__frame"
        :src="`/vast-preview.html?uid=${componentId}`"
        frameborder="0"
      />
    </div>

    <div v-show="statePreview === 'loading'" class="preview__loading">
      <v-progress-circular color="primary" indeterminate />
    </div>

    <div v-show="previewIsUpdate && statePreview === 'error'" class="preview__empty">
      <div class="mt-3">
        <div v-for="errorText in fullErrors" :key="errorText" class="v-messages__message white--text">
          {{ errorText }}
        </div>
      </div>
    </div>

    <div v-show="!previewIsUpdate" class="preview__empty">
      <div class="mt-3">
        <div class="v-messages__message white--text">
          {{ $t('main.ad_form.update_preview') }}
        </div>
      </div>
    </div>

    <div v-if="statePreview === 'ended'" class="preview__ended">
      <c-btn
        fab
        depressed
        icon
        text
        color="primary"
        :icon-props="{
          icon: 'mdi-refresh',
          large: 'large'
        }"
        @click="updatePreview"
      />
    </div>
  </div>
</template>

<script>
  import debounce from 'debounce'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'
  import ValidationError from '@/services/classes/validation-error.js'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'

  export default {
    name: 'InstreamPreview',
    components: { CBtn },
    props: {
      creative: {
        type: Object,
        required: true
      },
      previewIsUpdate: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        statePreview: 'idle',
        errors: {},
        generatedVastTagXml: '',
        debouncedFetchVastTag: null
      }
    },
    computed: {
      ...mapState('adForm', ['type']),
      fullErrors() {
        return Object.values(this.errors).reduce((acc, errors) => [...acc, ...(errors || [])], [])
      },
      componentId() {
        return this._uid
      }
    },
    watch: {
      previewIsUpdate: {
        deep: true,
        handler() {
          this.debouncedFetchVastTag()
        }
      },
      generatedVastTagXml: 'updatePreview'
    },
    created() {
      this.debouncedFetchVastTag = debounce(this.fetchVastTag, 1000)

      if (this.previewIsUpdate) {
        this.fetchVastTag()
      }
    },
    mounted() {
      window.addEventListener('message', this.onFrameMessage, false)
      this.$on('beforeDestroy', () => {
        window.removeEventListener('message', this.onFrameMessage)
      })
    },
    methods: {
      async fetchVastTag() {
        this.setStatePreview('loading')
        this.errors = {}

        try {
          this.generatedVastTagXml = await adsRepository.preview(
            Ads.getPreviewRequest(this.creative)
          )
        } catch (error) {
          if (!error.response || !error.response.data || !error.response.data.errors) {
            this.$showErrorNotification(error.message)
            return
          }
          const errors = new ValidationError({
            ...error.response.data.errors
          })
          this.errors = errors.messages

          this.setStatePreview('error')
          this.generatedVastTagXml = ''
        }
      },
      setStatePreview(statePreview) {
        this.statePreview = statePreview
      },
      async updatePreview() {
        if (!this.generatedVastTagXml) {
          return
        }
        this.setStatePreview('playing')
        await this.$nextTick()
        if (this.$refs.frame) {
          this.$refs.frame.contentWindow.postMessage({
            uid: this.componentId,
            action: 'initPreview',
            vastTagXml: this.generatedVastTagXml
          })
        }
      },
      onFrameMessage(event) {
        if (String(event.data.uid) !== String(this.componentId)) {
          return
        }

        if (event.data.action === 'ready' && this.generatedVastTagXml) {
          this.updatePreview()
        }

        if (event.data.action === 'previewError') {
          this.setStatePreview('idle')
        }

        if (event.data.action === 'end') {
          this.$emit('end')
          this.setStatePreview('ended')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .preview {
    min-height: 100%;
    background: var(--v-secondary-lighten-base);

    &__ended,
    &__loading {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__main {
      width: 100%;
      height: 100%;
    }

    &__frame {
      width: 100%;
      height: 100%;
    }

    &__empty {
      align-self: center;
      text-align: center;
      color: rgba(#000, 0.34);
      overflow: hidden;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
</style>
