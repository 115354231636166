<template>
  <div class="border-primary rounded fill-height d-flex justify-center align-center action-list py-5 py-sm-3 px-2">
    <confirm-dialog ref="archiveAcceptDialog" @confirm="removeAd('delete')">
      <template #title>
        {{ $t('main.ad_form.archive_ad_title', { name: ad.name }) }}
      </template>
      <template #body>
        {{ $t('main.ad_form.archive_ad', { name: ad.name }) }}
      </template>
    </confirm-dialog>
    <div
      v-for="(actionItem, index) in actions"
      :key="index"
      class="text-center action-width"
    >
      <table-action
        :settings-action="actionItem"
        icon
        @refresh="$emit('update')"
        @edit="editAd()"
        @statistics="openStatistics()"
        @duplicable="openDuplicateForm()"
        @archive="$refs.archiveAcceptDialog.ask()"
      />
      <div
        class="secondary-darken--text text-truncate text-caption mt-2 mt-lg-1"
      >
        {{ actionItem.text }}
      </div>
    </div>
  </div>
</template>

<script>
  import TableAction from '@clickadilla/components/ui/TableAction.vue'
  import routeNames from '@/types/route-names.js'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import Ads from '@/services/classes/Ads.js'
  import ConfirmDialog from '@/components/ConfirmDialog.vue'

  export default {
    name: 'NewAdsActions',
    components: {
      TableAction,
      ConfirmDialog
    },
    props: {
      ad: {
        type: Ads,
        default: () => ({})
      },
      isLoading: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        isArchiveDialogIsShown: false,
        deleteIsLoading: false
      }
    },
    computed: {
      actions() {
        return [
          {
            color: 'primary',
            method: 'refresh',
            icon: '$update',
            text: this.$t('ads.refresh'),
            disabled: !this.isLoading,
            isLoading: false
          },
          {
            color: 'primary',
            method: 'edit',
            icon: '$pencil',
            text: this.$t('main.ad_form.action_edit', { name: this.ad.name }),
            disabled: this.ad.actions.editable && !this.isLoading,
            isLoading: this.deleteIsLoading
          },
          {
            color: 'info',
            method: 'statistics',
            icon: '$chart-line',
            text: this.$t('main.ad_form.action_statistics', { name: this.ad.name }),
            disabled: !this.isLoading,
            isLoading: this.deleteIsLoading
          },
          {
            color: 'black',
            method: 'duplicable',
            icon: '$copy',
            text: this.$t('main.ad_form.duplicate', { name: this.ad.name }),
            disabled: this.ad.actions.duplicable && !this.isLoading
          },
          {
            color: 'error',
            method: 'archive',
            icon: '$trash',
            text: this.$t('main.ad_form.action_archive'),
            disabled: this.ad.actions.deletable && !this.isLoading,
            isLoading: this.deleteIsLoading
          }
        ]
      }
    },
    methods: {
      async openStatistics() {
        const campaignsIds = JSON.stringify(this.ad.campaigns.map(({ id }) => id))
        const adFormatIds = JSON.stringify(this.adFormatIds)
        await this.$router.push({
          name: routeNames.CAMPAIGN_STATISTICS,
          query: {
            campaigns: campaignsIds,
            groupBy: 'creative',
            adFormatIds
          }
        })
      },
      async editAd() {
        await this.$router.push({ name: routeNames.EDIT_AD, params: { id: this.ad.id } })
      },
      async openDuplicateForm() {
        await this.$router.push({ name: routeNames.DUPLICATE_AD, params: { id: this.ad.id } })
      },
      async removeAd() {
        this.deleteIsLoading = true
        try {
          await adsRepository.delete(this.ad.id)
          this.$showSuccessNotification(this.$t('main.ad_form.archived_ad', { name: this.ad.name }))
        } catch (error) {
          this.$showErrorNotification(this.$t('main.ad_form.error_archiving', { name: this.ad.name }))
        }
        this.deleteIsLoading = false
        this.$router.push({ name: routeNames.ADS })
      }
    }
  }
</script>

<style scoped lang="scss">
.action-list {
  gap: 10px;
}
.action-width {
  max-width: 95px;
  flex: 1 1 0;
  width: 0;
}
</style>
