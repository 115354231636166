<template>
  <v-dialog
    :value="previewIsFullscreen"
    :width="dialogWidth"
    @input="$emit('close-dialog')"
  >
    <v-card :height="dialogHeight" dark>
      <instream-preview :creative="creative" preview-is-update />
    </v-card>
  </v-dialog>
</template>

<script>
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import InstreamPreview from '@/components/ads/InstreamForm/InstreamPreview.vue'

  export default {
    name: 'InstreamPreviewDialog',
    components: { InstreamPreview },
    props: {
      creative: {
        type: Object,
        required: true
      },
      previewIsFullscreen: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      dialogWidth() {
        let width = this.isMobile ? '600' : '1000'
        if (this.creative.type === adTypes.REWARDED_POST) {
          width = '390'
        }
        return width
      },
      dialogHeight() {
        return this.creative.type === adTypes.REWARDED_POST ? '730px' : '50vh'
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    overflow-y: hidden;
  }
</style>
